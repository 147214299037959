import ESG_Stock_MarketTrend from './components/ESG_Stock_MarketTrend/ESG_Stock_MarketTrend';
import ESGIndustryRisk from './components/ESGIndustryRisk/ESGIndustryRisk';
import ESGTopicTrend from './components/ESGTopicTrend/ESGTopicTrend';
import {
  Container,
  TopicTrendWrapper,
  Stock_MarketTrendWrapper,
  IndustryRiskWrapper,
  NewsWrapper,
} from './HomeStyles';
import useEsgTopicTrend from './hooks/useEsgTopicTrend';
import useEsgMarketTrend from './hooks/useEsgMarketTrend';
import useNews from './hooks/useNews';
import useRiskRank from './hooks/useRiskRank';
import useFollowCompany from 'hooks/useFollowCompany';
import { useSelector } from 'react-redux';
import HomeNews from './components/HomeNews/HomeNews';
import Top from 'ui/transition/top/Top';
import SigninCTAModal from 'ui/modals/signInCTAModal/SigninCTAModal';
import { useState } from 'react';

export const Home = ({ sx }) => {
  const [esgTopicTrendData, isLoading_esgTopicTrend] = useEsgTopicTrend();
  const [esgMarketData, marketTrendCategory, handleRiskChange, isLoading_esgMarket] =
    useEsgMarketTrend();
  const [riskRankData, industryIndex, gicIndex, setIndustryIndex, setGicIndex, isLoading_riskRank] =
    useRiskRank();
  const [articles, isLoading_articles] = useNews();
  const [addFollowing, cancelFollowing] = useFollowCompany();
  const followingList = useSelector((state) => state.mylist.followingList);

  const user = useSelector((state) => state.user.info);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container style={{ ...sx }}>
      <TopicTrendWrapper>
        <ESGTopicTrend data={esgTopicTrendData} isLoading={isLoading_esgTopicTrend} />
      </TopicTrendWrapper>

      <Stock_MarketTrendWrapper>
        <ESG_Stock_MarketTrend
          data={{ esgMarketData }}
          category={{ marketTrendCategory }}
          isLoading={{ isLoading_esgMarket }}
          handler={(target) => handleRiskChange(target)}
          addFollowing={Object.keys(user).length > 0 ? addFollowing : () => setIsOpen(true)}
          cancelFollowing={Object.keys(user).length > 0 ? cancelFollowing : () => setIsOpen(true)}
          followingList={followingList}
        />
      </Stock_MarketTrendWrapper>

      <IndustryRiskWrapper>
        <ESGIndustryRisk
          data={riskRankData}
          category={{ industryIndex, gicIndex }}
          handler={{
            industryIndexHandler: (target) => {
              setIndustryIndex(target);
              setGicIndex('esg');
            },
            gicIndexHandler: (target) => setGicIndex(target),
          }}
          addFollowing={Object.keys(user).length > 0 ? addFollowing : () => setIsOpen(true)}
          cancelFollowing={Object.keys(user).length > 0 ? cancelFollowing : () => setIsOpen(true)}
          followingList={followingList}
          isLoading={isLoading_riskRank}
        />
      </IndustryRiskWrapper>

      <NewsWrapper>
        <HomeNews data={articles} isLoading={isLoading_articles} />
      </NewsWrapper>

      <Top />

      <SigninCTAModal open={isOpen} onClose={() => setIsOpen(false)} redirection="home" />
    </Container>
  );
};

export default Home;
