import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';
import { media } from 'media';

export const Container = styled.div`
  max-width: ${(props) => props.maxWidth};
  box-sizing: border-box;
  width: 100%;
  min-height: 48px;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid ${COLORS.Primary500};
  box-shadow: 0px 4px 4px 0px rgba(218, 226, 236, 0.5);
  background: #fff;

  transition: box-shadow ease 0.5s, border-radius ease 0.5s;
  z-index: 2;

  :hover {
    box-shadow: 0px 4px 4px 0px #dae2ec;
  }

  ${media.mobile`
    min-height: 24px;
  `}
`;

export const InputWrapper = styled.div`
  padding: 4px 20px;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;

  &.active {
    padding: 5px 21px;
  }
`;

export const Input = styled.input`
  all: unset;
  width: 90%;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(140, 144, 159, 1);
  }
`;

export const CompanyListWrapper = styled.div`
  padding: 16px 20px;
  border-radius: ${(props) => (props.isLast ? '0px 0px 20px 20px' : '0px')};

  display: flex;
  gap: 12px;

  cursor: pointer;

  :hover {
    background-color: ${COLORS.Background100};
  }

  &.focus {
    background-color: ${COLORS.Background100};
  }
`;

export const CompanyNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ListWrapper = styled.div`
  display: none;
`;

export const HistoryWrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.mobile`
    display: none;
  `}
`;

export const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 12px;
`;
