import S3 from 'aws-sdk/clients/s3';
import { Credentials } from 'aws-sdk';
import * as xlsx from 'xlsx';
import moment from 'moment';

export const ReactS3Client = () => {
  const access = new Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  return new S3({
    credentials: access,
    region: process.env.REACT_APP_AWS_REGION,
    signatureVersion: 'v4',
  });
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const excelDownload = (arr, name) => {
  const ws = xlsx.utils.json_to_sheet(arr, { skipHeader: true });
  const wb = xlsx.utils.book_new();

  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, `${name ? name : ''} ${moment().format('YYYY-MM-DD')}.xlsx`);
};

export const csvDownload = async (data) => {
  let result;

  const fileName = `${moment().format('YYYY-MM-DD HH:mm:ss')}`;
  const csv = JSON.stringify(result);
  const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });

  if (window.navigator.appVersion.toString().indexOf('.NET') > -1) {
    window.navigator.msSaveBlob(blob, fileName + '.csv');
  } else {
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const jsonDownload = async (data) => {
  try {
    const fileName = `${moment().format('YYYY-MM-DD HH:mm:ss')}`;
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    if (window.navigator.appVersion.toString().indexOf('.NET') > -1) {
      window.navigator.msSaveBlob(blob, fileName + '.json');
    } else {
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + '.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {}
};

export const numberWithCommas = (x, unit = '') => {
  if (!x) return 'NA';
  let num = Number(x) % 1 === 0 ? Number(x) : Number(x).toFixed(2);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit || '-';
};

export const numberWithCommasMillion = (x, unit = 'M') => {
  if (!x) return 'NA';
  let num = Number(x) % 1 === 0 ? (Number(x) / 1000000).toFixed(0) : Number(x);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit || 'NA';
};

export const calcZoom = (period) => {
  if (!period) return;

  let result = {
    startDate: moment(),
    endDate: moment(),
  };

  switch (period) {
    case '1M':
      result.startDate = moment().subtract(1, 'months');
      result.endDate = moment();
      break;
    case '3M':
      result.startDate = moment().subtract(3, 'months');
      result.endDate = moment();
      break;
    case '6M':
      result.startDate = moment().subtract(6, 'months');
      result.endDate = moment();
      break;
    case '1Y':
      result.startDate = moment().subtract(1, 'years');
      result.endDate = moment();
      break;
    case '2Y':
      result.startDate = moment().subtract(2, 'years');
      result.endDate = moment();
      break;
    case '3Y':
      result.startDate = moment().subtract(3, 'years');
      result.endDate = moment();
      break;
    case '5Y':
      result.startDate = moment().subtract(5, 'years');
      result.endDate = moment();
      break;
    case '7Y':
      result.startDate = moment().subtract(7, 'years');
      result.endDate = moment();
      break;
    default:
      break;
  }
  return result;
};
