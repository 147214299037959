import styled from 'styled-components';
import { media } from 'media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: center;

  ${media.mobile`
    display: block;
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${media.mobile`
    display: block;
  `}
`;

export const ThirdMenuWrapper = styled.div`
  width: fit-content;

  ${media.mobile`
    display: flex;
    width: 100%;
    justify-content: center;
  `}
`;

export const LegendWrapper = styled.div`
  min-width: 230px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;

  ${media.mobile`
    display: block;
    margin: 0px 12px;
  `}
`;

export const SecondaryMenuWrapper = styled.div`
  ${media.mobile`
    margin: 0px 12px;
    overflow: auto;
    max-width: 100%;
  `}
`;

export const ChartContainer = styled.div`
  position: relative;
  height: 400px;

  ${media.mobile`
    overflow: auto;
  `}
`;

export const ChartWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
`;
