import { Container, MenuItemContainer } from './MobileMenu.styles';
import { useTranslation } from 'react-i18next';
import AnalysisIcon from './components/icons/AnalysisIcon';
import HomeIcon from './components/icons/HomeIcon';
import DashboardIcon from './components/icons/DashboardIcon';
import NewsIcon from './components/icons/NewsIcon';
import SettingsIcon from './components/icons/SettingsIcon';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import signout from 'assets/signout.svg';
import { useSelector } from 'react-redux';
import lang from 'assets/lang.svg';
import Button from 'ui/buttons/button/Button';
import useLanguage from 'hooks/useLanguage';
import useMedia from 'hooks/useMedia';

const MenuItem = ({ type, title, onClick, isSelected, sx }) => {
  return (
    <MenuItemContainer onClick={onClick} isSelected={isSelected} style={sx}>
      {type === 'dashboard' ? <DashboardIcon /> : null}
      {type === 'home' ? <HomeIcon /> : null}
      {type === 'analysis' ? <AnalysisIcon /> : null}
      {type === 'news' ? <NewsIcon /> : null}
      {type === 'settings' ? <SettingsIcon /> : null}
      {type === 'signOut' ? <img src={signout} /> : null}
      {type === 'signIn' ? <img src={signout} /> : null}
      <Typo color={COLORS.Text300}>{title}</Typo>
    </MenuItemContainer>
  );
};

const MobileMenu = ({ onClose }) => {
  const [t] = useTranslation('mainframe');
  const [loginT] = useTranslation('signIn');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.info);

  const handleSignout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('state');
    window.location.href = '/signin';
  };

  const handleNavigate = (func) => {
    func();
    onClose();
  };

  const { language, setLanguage } = useLanguage();
  const ko_props = {
    color: language === 'ko' ? '#fff' : '#000',
    background: language === 'ko' ? COLORS.Primary500 : COLORS.Background100,
  };

  const en_props = {
    color: language === 'en' ? '#fff' : '#000',
    background: language === 'en' ? COLORS.Primary500 : COLORS.Background100,
  };

  const [isMobile] = useMedia();

  return (
    <Container>
      <MenuItem
        type="dashboard"
        title={t('Dashboard')}
        isSelected={pathname === '/dashboard'}
        onClick={() => handleNavigate(() => navigate('/dashboard'))}
      />
      <MenuItem
        type="home"
        title={t('Home')}
        isSelected={pathname === '/'}
        onClick={() => handleNavigate(() => navigate('/'))}
      />
      {Object.keys(user).length > 0 && !isMobile && (
        <MenuItem
          type="analysis"
          title={t('Analytics')}
          isSelected={pathname === '/analytics'}
          onClick={() => handleNavigate(() => navigate('/analytics'))}
        />
      )}
      <MenuItem
        type="news"
        title={t('News')}
        isSelected={pathname === '/news'}
        onClick={() => handleNavigate(() => navigate('/news'))}
      />
      {Object.keys(user).length > 0 ? (
        <>
          <MenuItem
            type="settings"
            title={t('Account Settings')}
            isSelected={pathname === '/settings'}
            onClick={() => handleNavigate(() => navigate('/settings'))}
          />
          <Divider />
          <MenuItem
            type="signOut"
            title={t('Signout')}
            isSelected={false}
            onClick={handleSignout}
          />
          <MenuItemContainer>
            <img src={lang} alt="lang" />
            <Typo color={COLORS.Text300}>{t('Language')}</Typo>
            <Button
              py="4px"
              px="8px"
              radius="1px"
              sx={{ ...ko_props }}
              onClick={() => setLanguage('kr')}
            >
              한국어
            </Button>
            <Button
              py="4px"
              px="8px"
              radius="1px"
              sx={{ ...en_props }}
              onClick={() => setLanguage('en')}
            >
              English
            </Button>
          </MenuItemContainer>
        </>
      ) : (
        <>
          <Divider />
          <MenuItem
            type="signIn"
            title={loginT('Login')}
            isSelected={false}
            onClick={() => (window.location.href = '/signin')}
          />
        </>
      )}
    </Container>
  );
};

export default MobileMenu;
