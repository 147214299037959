import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {
  Privacy,
  Terms,
  Verify,
  Analytics,
  Signin,
  Signup,
  Dashboard,
  Home,
  News,
  CompanyDetails,
} from 'pages';
import Highcharts from 'highcharts';
import MainFrameAuth from 'frames/MainFrameAuth';
import { GRAPH2, BENCHMARKCOLORS } from 'styles/colorTheme';
import AccountSettingsPage from 'pages/AccountSettings';

Highcharts.setOptions({
  colors: [...BENCHMARKCOLORS, ...GRAPH2],
  chart: {
    style: {
      fontFamily:
        "'Mulish', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
  },
  time: {
    useUTC: false,
    timezone: 'Asia/Seoul',
  },
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainFrameAuth />}>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/home" exact={true} element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
          <Route path="/company/:id" exact={true} element={<CompanyDetails />} />
          <Route path="/analytics/:id?" element={<Analytics />} />
          <Route path="/settings" element={<AccountSettingsPage />} />
        </Route>
        <Route path="privacy" exact={true} element={<Privacy />} />
        <Route path="verify" exact={true} element={<Verify />} />
        <Route path="terms" exact={true} element={<Terms />} />
        <Route path="signup" exact={true} element={<Signup />} />
        <Route path="signin/:id" exact={true} element={<Signin />} />
        <Route path="signin/company/:id" exact={true} element={<Signin />} />
        <Route path="*" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
