import BreadCrumb from 'ui/breadCrumb/BreadCrumb';
import {
  Container,
  ContentsWrapper,
  CompanyInfoWrapper,
  CompanyNameWrapper,
  CompanyScoreWrapper,
  ChipWrapper,
  ButtonWrapper,
  MobileButtonWrapper,
  MenuWrapper,
} from './CompanyDetailMenuStyles';
import Typo from 'ui/typo/Typo';
import { COLORS } from 'styles/colorTheme';
import UpDownChip from 'ui/caption/score3/Score3';
import AddButton from 'ui/buttons/addButton/AddButton';
import PrimaryMenu from 'ui/menus/primaryMenu/PrimaryMenu';
import { Skeleton } from '@mui/material';
import Tag from 'ui/tag/Tag';
import { useTranslation } from 'react-i18next';
import useFollowCompany from 'hooks/useFollowCompany';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompanyEsgProfile } from 'api/companyDetail';
import { useEffect, useState } from 'react';
import SigninCTAModal from 'ui/modals/signInCTAModal/SigninCTAModal';

const CompanyDetailMenu = ({ menuIdx, setMenuIdx, sx }) => {
  const language = useSelector((state) => state.user.language);
  const [t] = useTranslation('companyDetails');
  const { id } = useParams();
  const followingList = useSelector((state) => state.mylist.followingList);
  const isFollowing = followingList.find((el) => el.corp_code === id);

  const [addFollowing, cancelFollowing] = useFollowCompany(id);
  const [profileData, setProfileData] = useState({});
  const { stock_code, esg_risk, esg_risk_change_ratio } = profileData;
  const esg_risk_change_ratio_ = esg_risk_change_ratio
    ? Number(esg_risk_change_ratio.toFixed(2))
    : undefined;

  useEffect(() => {
    if (id) getProfileData();
  }, [id]);

  const getProfileData = async () => {
    const res = await getCompanyEsgProfile(id);
    if (res?.data) setProfileData(res.data);
  };

  const handleTapChange = (i) => {
    setMenuIdx(i);
  };

  const user = useSelector((state) => state.user.info);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container style={{ ...sx }}>
      {profileData[`sics_sector_name_${language}`] ? (
        <BreadCrumb
          list={[
            t('Home'),
            profileData[`sics_sector_name_${language}`],
            profileData[`sics_industry_name_${language}`],
            profileData[`comp_name_${language}`] + ' • ' + profileData[`stock_market_${language}`],
          ]}
        />
      ) : (
        <Skeleton variant="rounded" width={250} height={16} />
      )}
      <ContentsWrapper>
        <CompanyInfoWrapper>
          <CompanyNameWrapper>
            {profileData[`comp_name_${language}`] ? (
              <>
                <Typo variant="h1" color={COLORS.Text500} sx={{ alignSelf: 'center' }}>
                  {profileData[`comp_name_${language}`]}
                </Typo>
                <Tag>{stock_code}</Tag>
              </>
            ) : (
              <>
                <Skeleton variant="rounded" width={48} height={33} />
                <Skeleton variant="rounded" width={130} height={33} />
              </>
            )}
            <MobileButtonWrapper>
              <AddButton
                isAdded={isFollowing}
                onClick={
                  Object.keys(user).length > 0
                    ? isFollowing
                      ? cancelFollowing
                      : addFollowing
                    : () => setIsOpen(true)
                }
                sx={{
                  height: '24px',
                  width: '24px',
                  padding: '4px',
                  justifyContent: 'center',
                }}
              ></AddButton>
            </MobileButtonWrapper>
          </CompanyNameWrapper>
          <CompanyScoreWrapper>
            {profileData.esg_risk ? (
              <Typo
                variant="d1"
                className="medium"
                color={
                  esg_risk_change_ratio_ === 0
                    ? COLORS.Text400
                    : esg_risk_change_ratio_ < 0
                    ? COLORS.Blue200
                    : COLORS.Red200
                }
              >
                {esg_risk.toFixed(2)}
              </Typo>
            ) : (
              <Skeleton variant="rounded" width={76} height={38} />
            )}
            <ChipWrapper>
              {profileData.esg_risk_change_ratio ? (
                <UpDownChip change={esg_risk_change_ratio_} />
              ) : (
                <Skeleton variant="rounded" width={69} height={24} />
              )}
            </ChipWrapper>
          </CompanyScoreWrapper>
        </CompanyInfoWrapper>
        <ButtonWrapper>
          <AddButton
            isAdded={isFollowing}
            onClick={
              Object.keys(user).length > 0
                ? isFollowing
                  ? cancelFollowing
                  : addFollowing
                : () => setIsOpen(true)
            }
          >
            {isFollowing ? t('Added to dashboard') : t('Add to dashboard')}
          </AddButton>
        </ButtonWrapper>
      </ContentsWrapper>

      <MenuWrapper>
        <PrimaryMenu
          activeIndex={menuIdx}
          margin="0px 60px"
          menuList={[t('Summary'), t('Timeline'), t('Benchmark'), t('News')]}
          onClick={Object.keys(user).length > 0 ? handleTapChange : () => setIsOpen(true)}
        />
      </MenuWrapper>

      <SigninCTAModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        redirection={`company/${id}`}
      />
    </Container>
  );
};

export default CompanyDetailMenu;
