import { useState } from 'react';
import { Typography, Stack, Button, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IndexAnalysisChart from 'components/analyticsPage/indexAnalysis';
import LoadingCheck from 'components/analyticsPage/loadingCheck/LoadingCheck';
import IndexDrawer from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer/IndexDrawer';
import { useDownload } from 'components/analyticsPage/tabPanels/hooks/useDownload';
import { useIndexAnalysis } from 'components/analyticsPage/tabPanels/components/indexAnalysis/hooks/useIndexAnalysis';
import { useSelector } from 'react-redux';
import { useResetIndexDrawer } from 'components/analyticsPage/tabPanels/components/indexAnalysis/hooks/useResetDrawer';
import ModalComponent from 'ui/modals/defaultModal/Modal';
import ComponentPreloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { getUserData } from 'api/user';

export const IndexAnalysis = () => {
  const [column, rows, series, isNoResponse, loading] = useIndexAnalysis();
  const [openIndexDrawer, setOpenIndexDrawer] = useState(false);
  const indexQuery = useSelector((state) => state.analytics.indexQuery);
  const user = useSelector((state) => state.user.info);
  const [download] = useDownload(column, rows, indexQuery.companyName_en);
  useResetIndexDrawer([openIndexDrawer]);

  const [openModal, setOpenModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleDownloadClick = async () => {
    try {
      const userCheck = await getUserData(user.id);
      if (userCheck?.data?.user_type === 'enterprise') {
        download();
      } else {
        setOpenModal(true);
      }
    } catch (e) {
      window.alert('Failed download.');
    }
  };

  const iframLoaded = () => {
    setIframeLoading(false);
  };

  const handleModalClose = () => {
    setIframeLoading(true);
    setOpenModal(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">Index Analysis</Typography>
          <Tooltip title="Index Analytics provide an in-depth analysis function that can analyze companies by ESG topics, and provide long-term and short-term ESG risk indicators.">
            <InfoOutlinedIcon fontSize="10px" sx={{ cursor: 'pointer', ml: '5px' }} />
          </Tooltip>
        </Stack>
        <Stack justifyContent="space-between" direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={2} height="100%">
            <Button
              variant="contained"
              startIcon={<TuneIcon />}
              sx={{ height: '100%' }}
              onClick={() => setOpenIndexDrawer(true)}
            >
              Settings
            </Button>
          </Stack>
          <Button
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            sx={{ height: '100%', '@media(max-width: 768px)': { display: 'none' } }}
            onClick={handleDownloadClick}
          >
            Export
          </Button>
        </Stack>

        {/* Chart UI */}
        <Stack sx={{ overflowX: 'auto' }}>
          <LoadingCheck isLoading={loading} isNoResponse={isNoResponse}>
            <IndexAnalysisChart data={series} />
          </LoadingCheck>
        </Stack>

        {/* Drawer UI */}
        <IndexDrawer
          open={openIndexDrawer}
          data={indexQuery}
          action="update"
          closeDrawer={() => setOpenIndexDrawer(false)}
        />
      </Stack>
      <ModalComponent open={openModal} onClose={handleModalClose} width="fit-content">
        <div style={{ position: 'relative' }}>
          {iframeLoading && (
            <div style={{ position: 'absolute', left: '50%', top: '50%', translate: '-50% -50%' }}>
              <ComponentPreloader />
            </div>
          )}
          <div style={{ height: '30px' }} />
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSepD-PA2voKvTytaM1cfrsCjVVNCB6aXq7md1Pp_E0usIEx8A/viewform?embedded=true"
            width={640}
            height={window.innerHeight * 0.7}
            onLoad={iframLoaded}
          />
        </div>
      </ModalComponent>
    </>
  );
};

export default IndexAnalysis;
