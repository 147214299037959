import { useEffect, useState } from 'react';
import Tabs from 'components/analyticsPage/tabs';
import Wrapper from 'ui/wrapper';
import SubCard from 'ui/card/subCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TabPanels from 'components/analyticsPage/tabPanels/TabPanels';
import { getAnalyticsTabList } from 'api/analytics';
import { saveAnalyticsTabList } from 'services/analytics/Action';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import useAmplitude from 'hooks/useAmplitude';
import SigninCTAModal from 'ui/modals/signInCTAModal/SigninCTAModal';
import NoContent from 'ui/NoContent';
import SubscriptionCTAModal from 'ui/modals/subscriptionCTAModal/SubscriptionCTAModal';

const Analytics = () => {
  useAmplitude('analytics');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.info);
  const fetchTabList = async () => {
    const results = await getAnalyticsTabList(user.id);
    dispatch(saveAnalyticsTabList(results.data));
  };

  const location = useLocation();
  const [selectTab] = useTabs();
  const { tabList } = useSelector((state) => state.analytics);
  const [isOpen, setIsOpen] = useState(false);

  const isValidUser = user?.user_type === 'pro' || user?.user_type === 'enterprise' ? true : false;

  useEffect(() => {
    if (!isValidUser) return setIsOpen(true);
    fetchTabList();
    if (location?.state) {
      selectTab(tabList.length);
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Wrapper className="content-wrapper">
        {isValidUser ? (
          <SubCard
            sx={{
              px: 8,
              py: 1,
              minHeight: '78h',
              '@media(max-width: 768px)': { padding: '30px 0px' },
            }}
          >
            <Tabs />
            <TabPanels />
          </SubCard>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '80vh',
            }}
          >
            <NoContent />
          </div>
        )}
      </Wrapper>
      <SubscriptionCTAModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        redirection="analytics"
      />
    </DndProvider>
  );
};

export default Analytics;
