import { useEffect, useState } from 'react';
import industrySectormap from 'data/industrySectormap.json';
import { getIndustryNews } from 'api/news';
import * as amplitude from '@amplitude/analytics-browser';
import { useSelector } from 'react-redux';

const useIndustryNews = () => {
  let industries = [];
  industrySectormap.forEach((sector) => {
    industries.push(...sector.industry);
  });

  const user = useSelector((state) => state.user.info);
  const [industryIdx, setIndustryIdx] = useState(0);
  const [industryNewsData, setIndustryNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const handleMenu = (industryIdx) => {
    setIndustryIdx(industryIdx);

    amplitude.track('news_industry_news_view event', {
      category: industries[industryIdx]?.IndustryName_en,
    });
    setPage(1);
  };
  const [totalN, setTotalN] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length > 0) getData();
  }, [industryIdx, page]);

  const getData = async () => {
    setIsLoading(true);
    const res = await getIndustryNews(page, 20, industries[industryIdx]['IndustryCode']);
    if (!res) {
      setIndustryNewsData([]);
      setTotalN(0);
    } else {
      setIndustryNewsData(res.data.data);
      setTotalN(res.data.count);
    }
    setIsLoading(false);
  };

  return [industries, industryIdx, industryNewsData, totalN, page, setPage, handleMenu, isLoading];
};

export default useIndustryNews;
