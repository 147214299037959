import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from 'styles/colorTheme';

const EsgRiskChart = ({ title, isPositive = true, stockData, nfrData, esgRiskLegendInfo }) => {
  const [t] = useTranslation('chart');

  let stockMin = Number.MAX_SAFE_INTEGER;
  let stockMax = Number.MIN_SAFE_INTEGER;

  let nfrMin = Number.MAX_SAFE_INTEGER;
  let nfrMax = Number.MIN_SAFE_INTEGER;

  const stockData_ = stockData?.map((el, i) => {
    if (el[1] < stockMin) stockMin = el[1];
    if (el[1] > stockMax) stockMax = el[1];
    const data = { x: Number(moment(el[0]).format('x')), y: el[1] };
    return data;
  });

  const nfrData_ = nfrData?.map((el, i) => {
    if (el[1] < nfrMin) nfrMin = el[1];
    if (el[1] > nfrMax) nfrMax = el[1];
    const data = { x: Number(moment(el[0]).format('x')), y: el[1] };
    return data;
  });

  const [options, setOptions] = useState({});

  useEffect(() => {
    if (nfrData_.length > 0) {
      const _options = {
        chart: {
          type: 'areaspline',
          zoomType: 'x',
          resetZoomButton: {
            theme: {
              fill: '#f1f3f4',
              stroke: 'none',
              r: 12,
              states: {
                hover: {
                  fill: '#e4f2fd',
                  style: {
                    color: 'white',
                  },
                },
              },
            },
          },
        },
        title: {
          text: title,
          align: 'center',
        },
        xAxis: {
          visible: true,
          type: 'datetime',
          lineWidth: 1,
          lineColor: '#C5CFDD',
          tickWidth: 0,
          labels: {
            align: 'center',
            style: {
              fontSize: '12px',
              color: '#515562',
            },
            formatter: function () {
              return moment(this.value).format('YY/MM');
            },
          },
          crosshair: {
            color: '#78899F',
            dashStyle: 'LongDash',
          },
          minPadding: 0,
          maxPadding: 0,
          // tickPositioner: function () {
          //   let positions = [];
          //   let startDate = this.dataMin;
          //   let endDate = this.dataMax;

          //   while (startDate <= endDate) {
          //     positions.push(moment(startDate).date(15));
          //     startDate = moment(startDate).add(1, 'months').valueOf();
          //   }

          //   return positions;
          // },
        },
        yAxis: [
          {
            title: '',
            labels: {
              style: {
                fontSize: '12px',
                color: '#646876',
              },
              formatter: function () {
                return this.value.toFixed(2);
              },
            },
            visible: true,
            opposite: true,
            min: nfrMin,
            max: nfrMax,
          },
          {
            visible: false,
            opposite: true,
            min: stockMin,
            max: stockMax,
          },
        ],
        legend: { enabled: false },
        tooltip: {
          enabled: true,
          crosshair: true,
          shared: true,
          useHTML: true,
          borderRadius: 4,
          padding: 12,
          shadow: {
            offsetX: 0,
            offsetY: 3,
            opacity: 0.03,
          },
        },
        series: [
          {
            name: 'Nfr',
            data: nfrData_,
            yAxis: 0,
            visible: esgRiskLegendInfo[0].isVisible,
            tooltip: {
              xDateFormat: '%Y.%m.%d',
              headerFormat: `{point.key}`,
              pointFormatter: function () {
                return `
                <div style="margin-top: 10px;display:flex;align-items: center;gap: 4px;">
                  <div style="width: 8px;height: 8px;background-color: ${
                    this.color
                  };border-radius: 50%;"></div>
                  <div>${t('ESG risk')}: <span style="margin-left: 8px;">${this.y.toFixed(
                  2,
                )}</span></div>
                </div>
              `;
              },
            },
            color: isPositive ? COLORS.Red200 : COLORS.Blue200,
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, isPositive ? 'rgba(242, 54, 69, 0.2)' : 'rgba(41, 98, 255, 0.2)'],
                [1, isPositive ? 'rgba(242, 54, 69, 0.0)' : 'rgba(217, 217, 217, 0)'],
              ],
            },
            marker: {
              fillColor: isPositive ? COLORS.Red200 : COLORS.Blue200,
            },
          },
          {
            name: 'Stock',
            data: stockData_,
            yAxis: 1,
            visible: esgRiskLegendInfo[1].isVisible,
            tooltip: {
              xDateFormat: '%Y.%m.%d',
              headerFormat: `{point.key}`,
              pointFormatter: function () {
                let KRW = new Intl.NumberFormat('ko-KR', {
                  style: 'currency',
                  currency: 'KRW',
                }).format(this.y);
                return `
                <div style="margin-top: 10px;display:flex;align-items: center;gap: 4px;">
                  <div style="width: 8px;height: 8px;background-color: ${
                    this.color
                  };border-radius: 50%;"></div>
                  <div>${t('Stock price')}: <span style="margin-left: 8px;">${KRW}</span></div>
                </div>
              `;
              },
            },
            zIndex: 1,
            color: COLORS.Background200,
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, 'rgba(197, 207, 221, 0.2)'],
                [1, 'rgba(197, 207, 221, 0.0)'],
              ],
            },
            marker: {
              fillColor: COLORS.Background200,
            },
          },
        ],
        plotOptions: {
          series: {
            states: {
              hover: {
                halo: null,
              },
            },
          },
          areaspline: {
            lineWidth: 2.0,
            animation: false,
            marker: {
              enabled: false,
              radius: 5,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 0,
                },
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
      };
      setOptions(_options);
    } else {
      const _options = {
        chart: {
          type: 'areaspline',
          zoomType: 'x',
          resetZoomButton: {
            theme: {
              fill: '#f1f3f4',
              stroke: 'none',
              r: 12,
              states: {
                hover: {
                  fill: '#e4f2fd',
                  style: {
                    color: 'white',
                  },
                },
              },
            },
          },
        },
        title: {
          text: title,
          align: 'center',
        },
        xAxis: {
          visible: true,
          type: 'datetime',
          lineWidth: 1,
          lineColor: '#C5CFDD',
          tickWidth: 0,
          labels: {
            align: 'center',
            style: {
              fontSize: '12px',
              color: '#515562',
            },
            formatter: function () {
              return moment(this.value).format('YY/MM');
            },
          },
          crosshair: {
            color: '#78899F',
            dashStyle: 'LongDash',
          },
          minPadding: 0,
          maxPadding: 0,
          // tickPositioner: function () {
          //   let positions = [];
          //   let startDate = this.dataMin;
          //   let endDate = this.dataMax;

          //   while (startDate <= endDate) {
          //     positions.push(moment(startDate).date(15));
          //     startDate = moment(startDate).add(1, 'months').valueOf();
          //   }

          //   return positions;
          // },
        },
        yAxis: [
          {
            visible: false,
            opposite: true,
            min: stockMin,
            max: stockMax,
          },
        ],
        legend: { enabled: false },
        tooltip: {
          enabled: true,
          crosshair: true,
          shared: true,
          useHTML: true,
          borderRadius: 4,
          padding: 12,
          shadow: {
            offsetX: 0,
            offsetY: 3,
            opacity: 0.03,
          },
        },
        series: [
          {
            name: 'Stock',
            data: stockData_,
            visible: esgRiskLegendInfo[1].isVisible,
            tooltip: {
              xDateFormat: '%Y.%m.%d',
              headerFormat: `{point.key}`,
              pointFormatter: function () {
                let KRW = new Intl.NumberFormat('ko-KR', {
                  style: 'currency',
                  currency: 'KRW',
                }).format(this.y);
                return `
                <div style="margin-top: 10px;display:flex;align-items: center;gap: 4px;">
                  <div style="width: 8px;height: 8px;background-color: ${
                    this.color
                  };border-radius: 50%;"></div>
                  <div>${t('Stock price')}: <span style="margin-left: 8px;">${KRW}</span></div>
                </div>
              `;
              },
            },
            zIndex: 1,
            color: COLORS.Background200,
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, 'rgba(197, 207, 221, 0.2)'],
                [1, 'rgba(197, 207, 221, 0.0)'],
              ],
            },
            marker: {
              fillColor: COLORS.Background200,
            },
          },
        ],
        plotOptions: {
          series: {
            states: {
              hover: {
                halo: null,
              },
            },
          },
          areaspline: {
            lineWidth: 2.0,
            animation: false,
            marker: {
              enabled: false,
              radius: 5,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 0,
                },
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
      };
      setOptions(_options);
    }
  }, [nfrData, esgRiskLegendInfo]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default EsgRiskChart;
