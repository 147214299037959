import MainFrame from './MainFrame';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';
import { isMobileDevice } from 'utils/decive';
import { useTranslation } from 'react-i18next';

const MainFrameAuth = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [t] = useTranslation('etc');

  const identifyEvent = new amplitude.Identify();
  identifyEvent.set('id', user.info.id);
  identifyEvent.set('name', user.info.fullname);
  identifyEvent.set('userType', user.info.user_type);
  identifyEvent.set('email', user.info.email);

  amplitude.identify(identifyEvent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <MainFrame onClick={(target) => navigate(target)} address={location.pathname} {...props} />
    </>
  );
};

export default MainFrameAuth;
