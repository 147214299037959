import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;

  ${media.mobile`
    display: none;
  `}
`;

export const MobileContainer = styled.div`
  display: none;
  height: fit-content;
  padding: 10px 0px;
  position: relative;
  align-items: center;

  ${media.mobile`
    display: flex;
    gap: 10px;
  `};
`;

export const SearchBarWrapper = styled.div`
  position: absolute;
  left: ${window.innerWidth};
  top: 0px;
  width: 680px;
`;

export const HeaderMenuWrapper = styled.div`
  position: absolute;
  right: 0px;

  display: flex;
  gap: 12px;
`;

export const HambergerBtn = styled.div`
  display: none;

  ${media.laptop`
    display: inline-block;
    margin: auto;
  `}
`;

export const Input = styled.input`
  all: unset;
  width: 90%;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: rgba(140, 144, 159, 1);
  }
`;
