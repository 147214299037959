import ProfileIcon from 'ui/icon/profileIcon/ProfileIcon';
import { Container, Menu, IconWrapper, ModalWrapper, ButtonWrapper } from './HeaderMenuStyles';
import ProfileModal from 'ui/modals/profileModal/ProfileModal';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'ui/buttons/button/Button';
import { useTranslation } from 'react-i18next';
import Typo from 'ui/typo/Typo';
import languageImg from 'assets/language.svg';
import useLanguage from 'hooks/useLanguage';

const HeaderMenu = () => {
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state) => state.user.info);

  const { language, setLanguage } = useLanguage();

  const handleClick = () => setLanguage(language === 'ko' ? 'en' : 'kr');

  const modalRef = useRef();
  const iconRef = useRef();

  const handleClickOutSide = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !iconRef.current.contains(e.target)
    ) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('click', handleClickOutSide);
    } else {
      document.removeEventListener('click', handleClickOutSide);
    }

    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  }, [openModal]);

  const [t] = useTranslation('signIn');

  return (
    <>
      <Container>
        {Object.keys(user).length > 0 ? (
          <IconWrapper ref={iconRef}>
            <ProfileIcon onClick={() => setOpenModal((prev) => !prev)} />
          </IconWrapper>
        ) : (
          <ButtonWrapper>
            <div
              style={{
                position: 'relative',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: '10px',
                gap: '8px',
                cursor: 'pointer',
              }}
              onClick={handleClick}
            >
              <img src={languageImg} alt="language" />
              <Typo variant="btn2" className="regular">
                {language === 'ko' ? 'Korean' : 'English'}
              </Typo>
            </div>
            <Button onClick={() => (window.location.href = '/signin')}>{t('Login')}</Button>
          </ButtonWrapper>
        )}
      </Container>

      <ModalWrapper ref={modalRef}>
        <ProfileModal
          name={user.fullname}
          plan={user.user_type}
          email={user.email}
          isVisible={openModal}
          close={() => setOpenModal(false)}
        />
      </ModalWrapper>
    </>
  );
};

export default HeaderMenu;
