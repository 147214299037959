import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';

import * as authAPI from 'api/auth';
import ModalComponent from 'ui/modals/defaultModal';
import Typo from 'ui/typo/Typo';
import Input from 'ui/inputs/Input';
import Checkbox from 'ui/checkbox/defaultCheckbox/CheckBox';
import Button from 'ui/buttons/button/Button';
import { COLORS } from 'styles/colorTheme';
import check from 'assets/greencheck.svg';
import eye from 'assets/eye.svg';
import eyeSlash from 'assets/eye_slash.svg';
import {
  Container,
  SigninBtnContainer,
  SigninBtn,
  ValidityContainer,
  List,
  CheckBoxWrappwer,
  Eye,
} from './Signup.styles';
import * as amplitude from '@amplitude/analytics-browser';

export const DemoRequest = () => {
  const [t] = useTranslation('signUp');

  const [openModal, setOpenModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerification, setEmailVerification] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const navigate = useNavigate();
  const [validationState, setValidationState] = useState({
    letter: false,
    number: false,
    characters: false,
  });
  const [checklist, setChecklist] = useState([false, false, false]);
  const [showPassword, setShowPassword] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState(null);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(null);
  const [checklistErorrMsg, setChecklistErrorMsg] = useState(null);

  const checkBox = (index) => {
    let arr = checklist.slice();
    if (index === 0) {
      arr = arr.map((el) => !checklist[0]);
    } else {
      arr[index] = !arr[index];
    }
    setChecklist(arr);
  };

  const handleInput = (target, e) => {
    if (target === 'fullName') {
      setFullName(e.target.value);
    }
    if (target === 'email') {
      setEmailVerification(null);
      setEmail(e.target.value);
    }
    if (target === 'password') {
      setPassword(e.target.value);
      const checkResults = passwordValidationCheck(e.target.value);
      setValidationState({
        letter: checkResults.hasLetter,
        number: checkResults.hasNumber,
        characters: checkResults.has816Characters,
      });
    }
    if (target === 'passwordCheck') {
      setPasswordCheck(e.target.value);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const passwordValidationCheck = (string) => {
    let hasLetter = false;
    let hasNumber = false;
    let has816Characters = false;

    if (string.length >= 8 && string.length <= 16) {
      has816Characters = true;
    } else {
      has816Characters = false;
    }

    if (/\d/.test(string)) {
      hasNumber = true;
    } else {
      hasNumber = false;
    }

    if (/[a-zA-Z]/.test(string)) {
      hasLetter = true;
    } else {
      hasLetter = false;
    }
    return { hasLetter: hasLetter, hasNumber: hasNumber, has816Characters: has816Characters };
  };

  const emailValidationCheck = async () => {
    const emailRule =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    let arr = Object.assign({}, validationState);

    const result = await authAPI.checkEmail(email);
    if (result?.status === 200) {
      if (result.data.exist && emailRule.test(email)) {
        arr.email = true;
        setEmailVerification('This email account already exists.');
      }
      if (!result.data.exist && emailRule.test(email)) {
        arr.email = false;
        setEmailVerification('verified');
      }
      if (!result.data.exist && !emailRule.test(email)) {
        arr.email = false;
        setEmailVerification('This is not a correct email.');
      }
    }
  };

  const handleRegistration = async () => {
    if (fullName.length >= 257) {
      setNameErrorMsg('Name is too long.');
    } else if (fullName.length === 0) {
      setNameErrorMsg('Please enter your name.');
    } else {
      setNameErrorMsg(null);
    }

    if (password !== passwordCheck) {
      setPasswordErrorMsg('Passwords do not match.');
    } else if (!validationState.characters || !validationState.letter || !validationState.number)
      setPasswordErrorMsg('Password is not correct.');
    else setPasswordErrorMsg(null);

    if (!checklist[1] || !checklist[2])
      setChecklistErrorMsg('Please agree to the terms and conditions.');
    else setChecklistErrorMsg(null);

    if (emailVerification !== 'verified') {
      setEmailVerification('Please verify your email.');
    } else {
      setEmailVerification('verified');
    }

    if (
      fullName.length >= 257 ||
      fullName.length === 0 ||
      password !== passwordCheck ||
      !validationState.characters ||
      !validationState.letter ||
      !validationState.number ||
      emailVerification !== 'verified' ||
      !checklist[1] ||
      !checklist[2]
    )
      return;

    const formData = {
      fullname: fullName,
      email: email,
      password: password,
      password_confirm: passwordCheck,
    };
    const result = await authAPI.signup(formData);
    amplitude.track('signup_complete');
    if (result && result.status === 201) {
      amplitude.track('email_verification_start');
      setOpenModal(true);
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      handleRegistration();
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    navigate('/signin');
  };

  return (
    <>
      <Container>
        <Input
          value={fullName}
          placeHolder={t('Name')}
          onChange={(e) => handleInput('fullName', e)}
          helperText={nameErrorMsg && t(nameErrorMsg)}
          sx={{ height: '52px' }}
        />
        <Input
          value={email}
          placeHolder={t('Email')}
          onChange={(e) => handleInput('email', e)}
          rightComponent={
            <SigninBtn onClick={emailValidationCheck}>
              {emailVerification === 'verified' ? (
                <div style={{ width: 'max-content' }}>
                  <Typo variant="b2" color={COLORS.Primary500} className="regular">
                    {t('Available')}
                  </Typo>
                </div>
              ) : (
                <div style={{ width: 'max-content' }}>
                  <Typo variant="b2" color={COLORS.Primary500} className="regular">
                    {t('Check')}
                  </Typo>
                </div>
              )}
            </SigninBtn>
          }
          helperText={emailVerification !== 'verified' && t(emailVerification)}
          sx={{ height: '52px' }}
        />
        <Input
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeHolder={t('Password')}
          onChange={(e) => handleInput('password', e)}
          rightComponent={
            <Eye onClick={handleClickShowPassword} src={showPassword ? eyeSlash : eye} alt="eye" />
          }
          sx={{ height: '52px' }}
        />
        <Input
          value={passwordCheck}
          type={showPassword ? 'text' : 'password'}
          placeHolder={t('Confirm Password')}
          onChange={(e) => handleInput('passwordCheck', e)}
          rightComponent={
            <Eye onClick={handleClickShowPassword} src={showPassword ? eyeSlash : eye} alt="eye" />
          }
          helperText={passwordErrorMsg && t(passwordErrorMsg)}
          sx={{ height: '52px' }}
        />

        <ValidityContainer>
          <div style={{ marginBottom: '8px' }}>
            <Typo variant="b2" color={COLORS.Text400} className="regular">
              {t('Your password must contain:')}
            </Typo>
          </div>
          <List>
            {validationState.characters ? (
              <img src={check} width={13} alt="check" />
            ) : (
              <span>•</span>
            )}
            <Typo
              variant="b2"
              color={COLORS.Text400}
              className="regular"
              sx={{ display: 'inline-block', marginLeft: '12px' }}
            >
              {t('8~16 characters')}
            </Typo>
          </List>
          <List style={{ marginBottom: '6px' }}>
            {validationState.number && validationState.letter ? (
              <img src={check} width={13} alt="check" />
            ) : (
              <span>•</span>
            )}
            <Typo
              variant="b2"
              color={COLORS.Text400}
              className="regular"
              sx={{ display: 'inline-block', marginLeft: '12px' }}
            >
              {t('both numbers and letters')}
            </Typo>
          </List>
        </ValidityContainer>

        <ValidityContainer>
          <CheckBoxWrappwer>
            <Checkbox isChecked={checklist[0]} onClick={() => checkBox(0)} />
            <Typo variant="btn2" color={COLORS.Text400} className="regular">
              {t('Agree all')}
            </Typo>
          </CheckBoxWrappwer>

          <Divider sx={{ margin: '6px 0' }} />

          <CheckBoxWrappwer>
            <Checkbox isChecked={checklist[1]} onClick={() => checkBox(1)} />
            <Typo variant="btn2" color={COLORS.Text400} className="regular">
              {t('[Required] I am 14 years of age or older')}
            </Typo>
          </CheckBoxWrappwer>

          <CheckBoxWrappwer>
            <div>
              <Checkbox isChecked={checklist[2]} onClick={() => checkBox(2)} />
            </div>
            <div>
              <Typo variant="btn2" color={COLORS.Text400} className="regular">
                {t('[Required] I agree to ')}
              </Typo>
              <div
                onClick={() => window.open('/terms', '_blank')}
                style={{ display: 'inline-block', cursor: 'pointer' }}
              >
                <Typo
                  variant="btn2"
                  color={COLORS.Text400}
                  sx={{ textDecoration: 'underline' }}
                  className="regular"
                >
                  {t('Terms of Use')}
                </Typo>
              </div>
              <Typo variant="btn2" color={COLORS.Text400}>
                {t(' and ')}
              </Typo>
              <div
                onClick={() => window.open('/privacy', '_blank')}
                style={{ display: 'inline-block', cursor: 'pointer' }}
              >
                <Typo
                  variant="btn2"
                  color={COLORS.Text400}
                  sx={{ textDecoration: 'underline' }}
                  className="regular"
                >
                  {t('Privacy Policy')}
                </Typo>
              </div>
            </div>
          </CheckBoxWrappwer>
        </ValidityContainer>
        {checklistErorrMsg && (
          <Typo variant="b2" color={COLORS.Red200}>
            {t(checklistErorrMsg)}
          </Typo>
        )}
        <Button
          onKeyDown={(e) => handleEnter(e)}
          onClick={() => handleRegistration()}
          sx={{ height: 50, width: '100%' }}
        >
          <Typo variant="btn1" className="regular">
            {t('Continue')}
          </Typo>
        </Button>

        <SigninBtnContainer>
          <Typo variant="b2" color={COLORS.Text400} className="regular">
            {t('Already have an account?')}
          </Typo>
          <SigninBtn onClick={() => navigate('/signin')}>
            <Typo variant="b2" color={COLORS.Primary500} className="regular">
              {t('Login')}
            </Typo>
          </SigninBtn>
        </SigninBtnContainer>
      </Container>

      <ModalComponent open={openModal} onClose={closeModal}>
        <Stack spacing={4} sx={{ marginBottom: '24px' }}>
          <Typo variant="h1">{t('Registration completed successfully')}</Typo>
          <Stack spacing={1}>
            <Typo>{t('We Appreciate Your Interest!')}</Typo>
            <Typo>{t('Please check your mailbox and verify your email account.')}</Typo>
          </Stack>
        </Stack>
      </ModalComponent>
    </>
  );
};

export default DemoRequest;
