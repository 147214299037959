import { COLORS } from 'styles/colorTheme';
import {
  Container,
  Input,
  CompanyListWrapper,
  InputWrapper,
  ListWrapper,
  ChipWrapper,
  HistoryWrapper,
  CompanyNameWrapper,
} from './SearchBarStyles';
import { useEffect, useRef, useState } from 'react';
import search from 'assets/search.svg';
import Typo from 'ui/typo/Typo';
import Chip from 'ui/chips/chip/Chip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSearchHistory } from 'api/search';
import * as amplitude from '@amplitude/analytics-browser';
import useMedia from 'hooks/useMedia';

const SearchBar = ({
  value = '',
  onChange,
  onEnter,
  maxWidth,
  handleNavigate,
  searchList = [],
  sx,
}) => {
  const [isMobile] = useMedia();
  const [t] = useTranslation('mainframe');
  const language = useSelector((state) => state.user.language);
  const [searchHistory, setSearchHistory] = useState([]);
  const user = useSelector((state) => state.user.info);

  const idxRef = useRef(undefined);

  useEffect(() => {
    idxRef.current = undefined;
  }, [searchList]);

  const onKeyDown = (e) => {
    if (e.code === 'Enter') {
      return onEnter(e);
    }

    if (e.code === 'Escape') {
      e.target.value = '';
      return onChange(e);
    }

    if (e.key === 'ArrowDown') {
      if (e.nativeEvent.isComposing) return;
      e.preventDefault();
      const list = containerRef.current.querySelectorAll('.search-results');
      if (!list || list.length <= 0) return;

      if (idxRef.current === undefined) {
        list[0].classList.add('focus');
        changeInputValue(list[0]);
        idxRef.current = 0;
        return;
      }

      if (idxRef.current >= list.length - 1) {
        list[list.length - 1].classList.remove('focus');
        list[0].classList.add('focus');
        changeInputValue(list[0]);
        idxRef.current = 0;
        return;
      }

      list[idxRef.current].classList.remove('focus');
      list[idxRef.current + 1].classList.add('focus');
      changeInputValue(list[idxRef.current + 1]);
      idxRef.current = idxRef.current + 1;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const list = containerRef.current.querySelectorAll('.search-results');
      if (!list || list.length <= 0) return;

      if (idxRef.current === undefined) {
        list[list.length - 1].classList.add('focus');
        changeInputValue(list[list.length - 1]);
        idxRef.current = list.length - 1;
        return;
      }

      if (idxRef.current <= 0) {
        list[0].classList.remove('focus');
        list[list.length - 1].classList.add('focus');
        changeInputValue(list[list.length - 1]);
        idxRef.current = list.length - 1;
        return;
      }

      list[idxRef.current].classList.remove('focus');
      list[idxRef.current - 1].classList.add('focus');
      changeInputValue(list[idxRef.current - 1]);
      idxRef.current = idxRef.current - 1;
    }
  };

  const changeInputValue = (element) => {
    if (!element) return;
    const text = element.querySelector('.company-name').textContent;
    inputRef.current.value = text;
  };

  const inputRef = useRef();
  const containerRef = useRef();
  const listRef = useRef();
  const [isActive, setIsActive] = useState(false);

  const getSearchHistoryList = async () => {
    const res = await getSearchHistory();
    if (!res) return;
    setSearchHistory(res.data);
  };

  const onFocus = () => {
    if (!containerRef.current) return;

    if (Object.keys(user).length > 0) {
      getSearchHistoryList();
    }

    containerRef.current.style.borderRadius = '20px';
    containerRef.current.style.border = 'none';
    containerRef.current.style.boxShadow = `0px 4px 12px 0px #C5CFDD`;

    const inputWrapper = containerRef.current.querySelector('.input-wrapper');
    inputWrapper.classList.add('active');

    listRef.current.style.display = 'block';

    inputRef.current.setAttribute('placeholder', t('Search company'));
    setIsActive(true);

    amplitude.track('search_start');
  };

  const onBlur = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      containerRef.current.style.borderRadius = '30px';
      containerRef.current.style.border = `1px solid ${COLORS.Primary500}`;
      containerRef.current.style.boxShadow = null;

      const inputWrapper = containerRef.current.querySelector('.input-wrapper');
      inputWrapper.classList.remove('active');

      onChange({ target: { value: '' } });

      listRef.current.style.display = 'none';

      inputRef.current.setAttribute('placeholder', '');
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;
    const instance = inputRef.current;
    if (isActive) {
      document.addEventListener('click', onBlur);
      instance.removeEventListener('focus', onFocus);
    } else {
      instance.addEventListener('focus', onFocus);
      document.removeEventListener('click', onBlur);
    }
    return () => {
      document.removeEventListener('click', onBlur);
      instance.removeEventListener('focus', onFocus);
    };
  }, [isActive]);

  const splitHighlightString = (fullString, highlightString) => {
    if (!fullString) return;
    let front = '';
    let highlight = '';
    let back = fullString;
    const _fullString = fullString.toLowerCase();
    const _highlightString = highlightString.toLowerCase();
    const textLength = highlightString.length;

    for (let i = 0; i < fullString.length; i++) {
      if (_fullString.slice(i, i + textLength) === _highlightString) {
        highlight = fullString.slice(i, i + textLength);
        back = fullString.slice(i + textLength, fullString.length);
        break;
      }
      front += fullString[i];
      back = back.substring(1);
    }

    return (
      <Typo variant="b2" color={COLORS.Text400}>
        <span>{front}</span>
        <span style={{ color: COLORS.Primary500 }}>{highlight}</span>
        <span>{back}</span>
      </Typo>
    );
  };

  const handleChipClick = (company) => {
    amplitude.track('search_history_click', { company_code: company.corp_code });
    handleNavigate(company.corp_code);
  };

  const handleListItemClick = (company) => {
    amplitude.track('search_complete', { company_code: company.corp_code });
    handleNavigate(company.corp_code);
  };

  return (
    <Container ref={containerRef} maxWidth={maxWidth} style={sx}>
      <InputWrapper className="input-wrapper">
        <img src={search} width={24} />
        <Input ref={inputRef} value={value} onChange={onChange} onKeyDown={onKeyDown} />
      </InputWrapper>

      <ListWrapper ref={listRef}>
        {Object.keys(user).length > 0 && (
          <HistoryWrapper>
            <Typo variant="b2" color={COLORS.Text300}>
              {t('Search history')}
            </Typo>
            <ChipWrapper>
              {searchHistory.length === 0 ? (
                <div>
                  <Typo variant="b2" color={COLORS.Text300}>
                    {t('No history')}
                  </Typo>
                </div>
              ) : (
                <>
                  {searchHistory.map((company, i) => (
                    <Chip key={i} selected={true} onClick={() => handleChipClick(company)}>
                      {company[`corp_name_${language}`]}
                    </Chip>
                  ))}
                </>
              )}
            </ChipWrapper>
          </HistoryWrapper>
        )}
        {searchList.slice(0, isMobile ? 5 : 10).map((company, i) => (
          <CompanyListWrapper
            className="search-results"
            id={i}
            key={i}
            isLast={searchList.slice(0, 10).length - 1 === i}
            onClick={() => handleListItemClick(company)}
          >
            {splitHighlightString(company[`stock_code`], value)}
            <CompanyNameWrapper>
              <div className="company-name">
                {splitHighlightString(company[`corp_name_${language}`], value)}
              </div>
              <Typo variant="b2" color={COLORS.Text400}>
                {company[`stock_market_${language}`]}
              </Typo>
            </CompanyNameWrapper>
          </CompanyListWrapper>
        ))}
      </ListWrapper>
    </Container>
  );
};

export default SearchBar;
