import { useEffect, useState } from 'react';
import ESGdimension from 'data/esgDimensionmap.json';
import { getEsgTopicNews } from 'api/news';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

const useEsgNews = () => {
  const user = useSelector((state) => state.user.info);
  const language = useSelector((state) => state.user.language);
  const [esgNewsMenu, setEsgNewsMenu] = useState({
    selectedDimension: 0,
    selectedGic: 0,
  });
  const dimensions = ESGdimension.map((esg) => esg[`industryName_${language}`]);
  const [esgNewsData, setEsgNewsData] = useState([]);
  const [page, setPage] = useState(1);

  const handleMenu = (dimension, gic) => {
    setEsgNewsMenu({
      selectedDimension: dimension,
      selectedGic: gic,
    });
    amplitude.track('news_esg_topics_view', {
      category: ESGdimension[dimension]?.industryName_en,
      sub_category: ESGdimension[dimension]?.GIC[gic]?.GIC_Name_en,
    });
    setPage(1);
  };

  const [totalN, setTotalN] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length > 0) getData();
  }, [esgNewsMenu, page]);

  const getData = async () => {
    setIsLoading(true);
    const res = await getEsgTopicNews(
      page,
      20,
      ESGdimension[esgNewsMenu.selectedDimension]['GIC'][esgNewsMenu.selectedGic]['GIC_taxcode'],
    );
    if (!res) {
      setEsgNewsData([]);
      setTotalN(0);
    } else {
      setEsgNewsData(res.data.data);
      setTotalN(res.data.count);
    }
    setIsLoading(false);
  };

  return [dimensions, esgNewsMenu, esgNewsData, totalN, page, setPage, handleMenu, isLoading];
};

export default useEsgNews;
