import BetaSubheader from 'ui/BetaSubheader';
import { Container, HeaderWrapper, BodyWrapper, SubheaderWrapper, Main } from './MainFrameStyles';
import Header from './components/header/Header';
import LeftPannel from './components/leftPannel/LeftPannel';
import { Outlet } from 'react-router-dom';
import { getLastValidDate } from 'api/user';
import { useEffect, useState } from 'react';
import moment from 'moment';

const MainFrame = ({ address, onClick, sx }) => {
  const [dataVlidation, setDataValidation] = useState();

  const checkDataValidation = async () => {
    const lastVisit = localStorage.getItem('lastVistit');
    if (lastVisit && !moment(lastVisit).isSame(moment(), 'date')) {
      const res = await getLastValidDate();
      if (!res.data.is_latest) {
        setDataValidation(res.data.latest_valid_date);
      } else setDataValidation(undefined);
    } else {
    }
    localStorage.setItem('lastVistit', moment().toISOString());
  };

  useEffect(() => {
    checkDataValidation();
  }, []);

  return (
    <>
      <SubheaderWrapper>
        <BetaSubheader open={dataVlidation} onClose={() => setDataValidation()} />
      </SubheaderWrapper>
      <Container style={{ ...sx }}>
        <LeftPannel address={address} onClick={onClick} />
        <Main>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>{Outlet ? <Outlet /> : null}</BodyWrapper>
        </Main>
      </Container>
    </>
  );
};

export default MainFrame;
