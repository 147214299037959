import { useEffect } from 'react';
import PrivacyComponent from 'components/privacyTerms/PrivacyComponent';

export const Privacy = () => {
  useEffect(() => {
    window.document.title = 'FairLabs 개인정보정책';
  }, []);

  return (
    <>
      <header>
        <div className="header">
          <div className="header-wrapper">
            <span className="pointer">
              <img
                width="120px"
                src="/images/header/logo.svg"
                alt=""
                onClick={(e) => (window.location.href = '/')}
              />
            </span>
          </div>
        </div>
      </header>
      <PrivacyComponent />
    </>
  );
};

export default Privacy;
