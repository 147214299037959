import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';

export const Container = styled.div`
  display: flex;
  padding: 4px 16px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border-bottom: 1px solid ${COLORS.Background100};
  background: ${(props) => (props.isSelected ? COLORS.Background50 : '#FFF')};
  position: relative;
  cursor: pointer;
  :hover {
    background-color: ${COLORS.Background50};
  }
`;

export const Label = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;

export const Bar = styled.div`
  width: 6px;
  height: 28px;
  background-color: ${(props) => props.tagColor};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;

  :hover {
    text-decoration: ${(props) => (props.navigator ? 'underline' : 'none')};
  }
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: ${(props) => (props.displayCancel ? '64px' : '')};
`;

export const ButtonWrapper = styled.div`
  display: ${(props) => (props.isVisible ? 'inline-block' : 'none')};
  position: absolute;
  right: 16px;
`;
