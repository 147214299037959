import { COLORS } from 'styles/colorTheme';
import Typo from './typo/Typo';
import logo from 'assets/logo.svg';

const NoContent = () => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} width="35px" />
        <Typo variant="h1" sx={{ marginLeft: '8px' }}>
          ESG LENS
        </Typo>
      </div>
      <div style={{ marginLeft: '4px' }}>
        <Typo variant="b2" color={COLORS.Text200}>
          AI-Powered ESG Insights
        </Typo>
      </div>
    </div>
  );
};

export default NoContent;
