import Typo from 'ui/typo/Typo';
import { Container, ChartWrapper, TitleWrapper, Title } from './EsgRiskExposure.styles';
import { COLORS } from 'styles/colorTheme';
import { useTranslation } from 'react-i18next';
import RiskBarChart from 'ui/charts/horizontalBarChart/RiskBarChart';

const EsgRiskExposure = ({ data, isLoading = true, sx }) => {
  const [t] = useTranslation('dashboard');

  return (
    <Container style={{ ...sx }}>
      <TitleWrapper>
        <Title>
          <Typo variant="h3" color={COLORS.Text500}>
            {t('ESG Risk Exposures')}
          </Typo>
          <Typo variant="b2" color={COLORS.Text200} sx={{ marginTop: '8px' }}>
            {t('Companies on my watchlist with high exposure to ESG risks and the proportion.')}
          </Typo>
        </Title>
      </TitleWrapper>
      <ChartWrapper>
        <RiskBarChart data={data} isLoading={isLoading} />
      </ChartWrapper>
      {/* {!isLoading && data?.values.length < 5 ? (
        <div style={{ marginLeft: '10px', textAlign: 'right' }}>
          <Typo variant="caption2" color={COLORS.Background300}>
            Please follow more comapnies.
          </Typo>
        </div>
      ) : null} */}
    </Container>
  );
};

export default EsgRiskExposure;
