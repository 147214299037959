import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${media.mobile`
    margin: 0px 12px;
  `}
`;

export const ContentsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile`
    display: block;
  `}
`;

export const CompanyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CompanyNameWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const CompanyScoreWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ChipWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  ${media.mobile`
    display: none;
  `}
`;

export const MobileButtonWrapper = styled.div`
  display: none;
  ${media.mobile`
    display: block;
  `}
`;

export const MenuWrapper = styled.div`
  ${media.mobile`
    min-width: 100%;
    width: 300px;
    overflow: auto;
  `}
`;
