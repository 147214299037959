import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';
import Typo from './typo/Typo';
import cancel from 'assets/cancel.svg';

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.Background200};
  display: ${(props) => (props.open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const ImgWrapper = styled.div`
  position: absolute;
  right: 0px;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  text-align: center;
  margin: 16px;
`;

export default function BetaSubheader({ open, onClose }) {
  const [t] = useTranslation('etc');

  return (
    <Container open={open}>
      <TextWrapper>
        <Typo color={COLORS.Background700} variant="h3">
          {t(
            'Server updates may temporarily limit service availability. We appreciate your understanding.',
          )}
        </Typo>
      </TextWrapper>
      <ImgWrapper onClick={onClose}>
        <img src={cancel} />
      </ImgWrapper>
    </Container>
  );
}
