import { useEffect } from 'react';
import * as authAPI from 'api/auth';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

export const Verify = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const code = params.get('code');

  useEffect(() => {
    emailVerify();
    window.document.title = 'FairLabs 이메일 인증';
  }, []);

  const emailVerify = async () => {
    if (!email || !code) {
      window.location.href = '/';
      return alert('잘못된 요청입니다.');
    }

    const result = await authAPI.emailVerify(email, code);

    if (result && result.status === 200) {
      amplitude.track('email_verification_complete');
      window.location.href = '/signin';
    } else {
      window.location.href = '/signin';
      return alert('잘못된 요청입니다.');
    }
  };

  return <></>;
};

export default Verify;
