import { Stack } from '@mui/material';
import Typo from 'ui/typo/Typo';
import ModalComponent from 'ui/modals/defaultModal';
import { useTabs } from 'components/analyticsPage/tabs/components/tabItems/hooks/useTabs';
import { useTranslation } from 'react-i18next';

const RemoveModal = ({ modalState, handleModal }) => {
  const [, removeTab] = useTabs();
  const [t] = useTranslation('analytics');

  return (
    <>
      {modalState.open ? (
        <ModalComponent
          open={modalState.open}
          onClose={() => handleModal()}
          onSubmit={() => removeTab(modalState.idx)}
        >
          <Stack>
            <Typo variant="b1">{t('Are you sure you want to delete?')}</Typo>
          </Stack>
        </ModalComponent>
      ) : null}
    </>
  );
};

export default RemoveModal;
