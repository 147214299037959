import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { getFollowListWithRisk } from 'api/followings';
import { getFollowList, getFollowListWithRisk } from 'api/followings';

const useFollowings = () => {
  const followingList = useSelector((state) => state.mylist.followingList);
  const [followingList_risk, setFollowingList_risk] = useState(followingList);
  const [corpcodes, setCorpcodes] = useState();

  const user = useSelector((state) => state.user.info);

  useEffect(() => {
    if (Object.keys(user).length > 0) getData();
  }, [followingList]);

  const getData = async () => {
    const res = await getFollowListWithRisk(followingList.map((list) => list.corp_code).join(','));
    if (!res) return;
    if (res.data.length === 0) return;
    setFollowingList_risk(res.data);
    const _corpCodes = res.data.map((el, i) => el.corp_code);
    setCorpcodes(_corpCodes);
  };

  return [followingList, followingList_risk, corpcodes];
};

export default useFollowings;
