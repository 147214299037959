import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SignupBtnContainer = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

export const SignupBtn = styled.span`
  cursor: pointer;
`;

export const ResendBtn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const RememberMeContainer = styled.div`
  display: inline-flex;
  margin-right: auto;
  align-items: center;
  cursor: pointer;
`;
