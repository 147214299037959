import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { useState, useEffect } from 'react';
import _ from 'lodash';

const BenchmarkChart = ({ title, nfrData }) => {
  const initialOptions = {
    chart: {
      type: 'spline',
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          fill: '#f1f3f4',
          stroke: 'none',
          r: 12,
          states: {
            hover: {
              fill: '#e4f2fd',
              style: {
                color: 'white',
              },
            },
          },
        },
      },
    },
    title: {
      text: title,
      align: 'center',
    },
    xAxis: {
      visible: true,
      type: 'datetime',
      lineWidth: 1,
      lineColor: '#C5CFDD',
      tickWidth: 0,
      labels: {
        align: 'center',
        style: {
          fontSize: '12px',
          color: '#515562',
        },
        formatter: function () {
          return moment(this.value).format('YY/MM');
        },
      },
      crosshair: {
        color: '#78899F',
        dashStyle: 'LongDash',
      },
      minPadding: 0,
      maxPadding: 0,
      // tickPositioner: function () {
      //   let positions = [];
      //   let startDate = this.dataMin;
      //   let endDate = this.dataMax;

      //   while (startDate <= endDate) {
      //     positions.push(moment(startDate).date(15));
      //     startDate = moment(startDate).add(1, 'months').valueOf();
      //   }

      //   return positions;
      // },
    },
    yAxis: [],
    legend: { enabled: false },
    tooltip: {
      enabled: true,
      crosshair: true,
      shared: true,
      useHTML: true,
      borderRadius: 4,
      padding: 12,
      shadow: {
        offsetX: 0,
        offsetY: 3,
        opacity: 0.03,
      },
    },
    series: [],
    plotOptions: {
      series: {
        states: {
          hover: {
            halo: null,
          },
        },
      },
      spline: {
        lineWidth: 2.0,
        marker: {
          enabled: false,
          radius: 5,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
              lineWidth: 0,
            },
          },
        },
        animation: false,
      },
    },
    credits: {
      enabled: false,
    },
  };

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    let series = [];
    let yAxis = [];

    nfrData?.map((company, i) => {
      yAxis.push({
        title: '',
        labels: {
          style: {
            fontSize: '12px',
            color: '#646876',
          },
          formatter: function () {
            return this.value + '%';
          },
        },
        visible: true,
        opposite: false,
        tickAmount: 6,
        plotLines: [
          {
            color: '#445366',
            width: 1.5,
            value: 0,
            zIndex: 1,
          },
        ],
      });

      const x = company.data[company.data.length - 1][0] || company.data[company.data.length - 1].x;
      const y = company.data[company.data.length - 1][1] || company.data[company.data.length - 1].y;

      const marker = {
        x: x,
        y: y,
        marker: {
          enabled: true,
          radius: 6,
          symbol: 'circle',
          lineWidth: 1,
          lineColor: company.color,
          states: {
            hover: {
              enabled: false,
              lineWidth: 0,
            },
          },
        },
      };

      const _data = [...company.data.slice(0, company.data.length - 1), marker];

      series.push({
        name: 'Nfr',
        data: _data,
        yAxis: 0,
        tooltip: {
          xDateFormat: '%Y.%m.%d',
          headerFormat: `{point.key}`,
          pointFormatter: function () {
            return `
              <div style="margin-top: 10px;display:flex;align-items: center;gap: 4px;">
                <div style="width: 8px;height: 8px;background-color: ${
                  company.color
                };border-radius: 50%;"></div>
                <div>${company.name} <span style="margin-left: 8px;">${this.y.toFixed(
              2,
            )}%</span></div>
              </div>
            `;
          },
        },
        color: company.color || '#000',
        events: {
          mouseOver: function () {
            const highchartsMarkers = document.querySelectorAll('.highcharts-point');

            if (highchartsMarkers.length === 0) return;

            for (let i = 0; i < highchartsMarkers.length; i++) {
              const strokeWidth = highchartsMarkers[i].getAttribute('strokeWidth');
              if (strokeWidth === '1') {
                highchartsMarkers[i]?.setAttribute('opacity', 0);
              }
            }
          },
          mouseOut: function () {
            const highchartsMarkers = document.querySelectorAll('.highcharts-point');

            if (highchartsMarkers.length === 0) return;

            for (let i = 0; i < highchartsMarkers.length; i++) {
              const strokeWidth = highchartsMarkers[i].getAttribute('strokeWidth');
              if (strokeWidth === '1') {
                highchartsMarkers[i]?.setAttribute('opacity', 1);
              }
            }
          },
        },
      });
    });

    const options_ = _.cloneDeep(options);
    options_.yAxis = yAxis;
    options_.series = series;

    setOptions(options_);
  }, [nfrData]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BenchmarkChart;
