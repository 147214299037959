import { useSelector } from 'react-redux';
import { Container } from './MenuStyles';
import MenuItem from './components/MenuItem';
import { useTranslation } from 'react-i18next';

const Menu = ({ address, onClick }) => {
  const [t] = useTranslation('mainframe');
  const user = useSelector((state) => state.user.info);

  return (
    <Container>
      <MenuItem
        type="dashboard"
        title={t('Dashboard')}
        isSelected={address === '/dashboard'}
        onClick={() => onClick('dashboard')}
      />
      <MenuItem
        type="home"
        title={t('Home')}
        isSelected={address === '/'}
        onClick={() => onClick('/')}
      />
      {Object.keys(user).length > 0 && (
        <MenuItem
          type="analysis"
          title={t('Analytics')}
          isSelected={address === '/analytics'}
          onClick={() => onClick('analytics')}
        />
      )}
      <MenuItem
        type="news"
        title={t('News')}
        isSelected={address === '/news'}
        onClick={() => onClick('news')}
      />
      {Object.keys(user).length > 0 && (
        <MenuItem
          type="settings"
          title={t('Account Settings')}
          isSelected={address === '/settings'}
          onClick={() => onClick('settings')}
        />
      )}
    </Container>
  );
};

export default Menu;
