import Button from 'ui/buttons/button/Button';
import DefaultModal from 'ui/modals/defaultModal';
import Typo from 'ui/typo/Typo';
import logo from 'assets/logo.svg';
import check from 'assets/check_green.svg';
import { useEffect } from 'react';
import { COLORS } from 'styles/colorTheme';
import { useTranslation } from 'react-i18next';

const SigninCTAModal = ({ open = false, onClose = () => {}, redirection = '' }) => {
  const [t] = useTranslation('signIn');

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open]);

  return (
    <DefaultModal open={open} onClose={onClose}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} width="35px" />
        <Typo variant="h1" sx={{ marginLeft: '8px' }}>
          ESG LENS
        </Typo>
      </div>
      <div style={{ marginLeft: '4px' }}>
        <Typo variant="b2" color={COLORS.Text200}>
          AI-Powered ESG Insights
        </Typo>
      </div>

      <div style={{ margin: '12px 0' }}>
        <div style={{ margin: '24px 0' }}>
          <div>
            <Typo variant="h1">{t('Need ESG Insights?')}</Typo>
          </div>
          <div style={{ marginTop: '6px' }}>
            <Typo variant="b2" color={COLORS.Text200}>
              {t('Please login and enjoy the following features for free.')}
            </Typo>
          </div>
        </div>
        <div style={{ margin: '0 12px 30px 12px' }}>
          <div style={{ marginTop: '6px' }}>
            <img src={check} style={{ marginRight: '4px' }} />
            <Typo>{t('Create your own portfolio')}</Typo>
          </div>
          <div style={{ marginTop: '6px' }}>
            <img src={check} style={{ marginRight: '4px' }} />
            <Typo>{t('Monitor your portfolios')}</Typo>
          </div>
          <div style={{ marginTop: '6px' }}>
            <img src={check} style={{ marginRight: '4px' }} />
            <Typo>{t('ESG categorized news')}</Typo>
          </div>
          <div style={{ marginTop: '6px' }}>
            <img src={check} style={{ marginRight: '4px' }} />
            <Typo>{t('Benchmark analysis')}</Typo>
          </div>
          <div style={{ marginTop: '6px' }}>
            <img src={check} style={{ marginRight: '4px' }} />
            <Typo>{t('Timeline analysis')}</Typo>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '8px' }}>
        <Button
          sx={{ width: '100%' }}
          onClick={() => (window.location.href = `/signin/${redirection}`)}
        >
          {t('Get Started')}
        </Button>
      </div>
    </DefaultModal>
  );
};

export default SigninCTAModal;
