import styled from 'styled-components';
import { COLORS } from 'styles/colorTheme';
import Typo from 'ui/typo/Typo';
import { useTranslation } from 'react-i18next';
import { media } from 'media';
import useEsgMarketTrend from 'components/homePage/hooks/useEsgMarketTrend';
import Preloader from 'ui/preloaders/componentPreloader/ComponentPreloader';
import { useSelector } from 'react-redux';
import Button from 'ui/buttons/button/Button';
import CheckboxScore from 'ui/listItem/score/CheckboxScore';
import { useState } from 'react';
import useFollowCompany from 'hooks/useFollowCompany';
import SigninCTAModal from 'ui/modals/signInCTAModal/SigninCTAModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 80vh;

  border: 1px solid rgba(208, 215, 222, 1);

  ${media.mobile`
    margin: 0px 12px;
    border-radius: 6px;
    height: 100%;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  ${media.mobile`
    margin: 24px 12px 0px 12px;
    border-radius: 6px;
    height: 100%;
  `}
`;

const BtnWrapper = styled.div`
  ${media.mobile`
    margin: 24px;
  `}
`;

const NoFollowings = () => {
  const [t] = useTranslation('noFollowings');
  const [esgMarketData, , , isLoading] = useEsgMarketTrend();
  const language = useSelector((state) => state.user.language);
  const [select, setSelect] = useState(new Set());
  const [addFollowing] = useFollowCompany();
  const user = useSelector((state) => state.user.info);

  const handleClick = (data) => {
    if (select.has(data)) {
      select.delete(data);
    } else {
      select.add(data);
    }
    setSelect(new Set(select));
  };

  const handleAdd = async () => {
    [...select].map((corpCode) => {
      addFollowing(corpCode);
    });
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <TitleWrapper>
        <Typo variant="h2">{t('Create your watchlist')}</Typo>
        <Typo variant="b2" color={COLORS.Text400} sx={{ margin: '0 6px' }}>
          {t('Track multiple companies and assess potential ESG risks within your watchlist.')}
        </Typo>
      </TitleWrapper>
      {isLoading ? (
        <Preloader width="1174px" height="540px" />
      ) : (
        <div style={{ maxWidth: '600px', width: '100%' }}>
          {esgMarketData.slice(0, 10).map((data, i) => (
            <CheckboxScore
              type={'pulse'}
              companyName={data[`comp_name_${language}`]}
              lightLineChartData={data.chart_data}
              score={data.value.toFixed(2)}
              scoreChange={data.change.toFixed(2)}
              onClick={() => handleClick(data.corp_code)}
              isSelected={select.has(data.corp_code)}
              key={i}
            />
          ))}
          <BtnWrapper>
            <Button
              sx={{ margin: 'auto', display: 'block', marginTop: '24px' }}
              onClick={Object.keys(user).length > 0 ? handleAdd : () => setIsOpen(true)}
            >
              {t('Add to list')}
            </Button>
          </BtnWrapper>
        </div>
      )}
      <SigninCTAModal open={isOpen} onClose={() => setIsOpen(false)} redirection="dashboard" />
    </Container>
  );
};

export default NoFollowings;
