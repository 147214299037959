import { media } from 'media';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const ViralBarsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile`
    display: none;
  `}
`;

export const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;

  ${media.mobile`
    display: block;
  `}
`;

export const MenuWrapper = styled.div`
  width: fit-content;
`;
