import axiosInstance from './apiInterceptor';
import axios from 'axios';

export const getCompanyEsgProfile = async (companyId) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/profile`,
  });
};

export const getEsgSummaryChart = async (companyId, category, time_range, isAnonymous) => {
  if (isAnonymous) {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API}/company/${companyId}/summary-chart`,
      params: {
        category: category,
        time_range: time_range,
      },
    });
  }

  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/summary-chart`,
    params: {
      category: category,
      time_range: time_range,
    },
  });
};

export const getEsgSummaryDetails = async (companyId) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/summary-details`,
  });
};

export const getEsgNegativeExposure = async (companyId, year, isAnonymous) => {
  if (isAnonymous) {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API}/company/${companyId}/esg-viral`,
      params: {
        year: year,
      },
    });
  }

  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/esg-viral`,
    params: {
      year: year,
    },
  });
};

export const getEsgBenchmarkChart = async (companyId, category, time_range) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/benchmark-chart`,
    params: {
      category: category,
      time_range: time_range,
    },
  });
};

export const getCompanySimilarities = async (companyId, limit = 10) => {
  return axiosInstance({
    method: 'GET',
    url: `/company/${companyId}/similarities`,
    params: {
      limit: limit,
    },
  });
};

export const getCompanyDetailNews = async (companyId, page = 1, per_page = 20) => {
  return axiosInstance({
    method: 'GET',
    url: `/news/${companyId}`,
    params: {
      page: page,
      per_page: per_page,
    },
  });
};

export const getCompanyDataValidation = async (companyId) => {
  return axiosInstance({
    method: 'GET',
    url: `company/${companyId}/data-validation`,
  });
};
