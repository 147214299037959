import { useState, useEffect } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GeneratorModal from 'components/analyticsPage/tabPanels/components/initiateCTA/components/generatorModal';
import TutorialModal from 'components/analyticsPage/tabPanels/components/initiateCTA/components/tutorialModal';
import DataDrawer from 'components/analyticsPage/tabPanels/components/drawers/dataDrawer';
import IndexDrawer from 'components/analyticsPage/tabPanels/components/drawers/indexDrawer';
import { useSelector } from 'react-redux';
import {
  useResetAllDataDrawer,
  useResetAllIndexDrawer,
  useResetDataFilters,
  useResetIndexFilters,
} from 'components/analyticsPage/tabPanels/components/initiateCTA/hooks/useResetDrawer';
import { useInitDrawer } from './hooks/useInitDrawer';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  width: '140px',
  backgroundColor: '#F1F5F9',
  boxShadow: 'none',
  color: '#475569',
  ':hover': {
    backgroundColor: '#F1F5F9',
    boxShadow: 'none',
  },
};

const InitiateCTA = () => {
  const [t] = useTranslation('analytics');

  const [openGeneratorModal, setOpenGeneratorModal] = useState(true);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const [openDataDrawer, setOpenDataDrawer] = useState(false);
  const [openIndexDrawer, setOpenIndexDrawer] = useState(false);
  const { dataQuery, indexQuery, eventQuery } = useSelector((state) => state.analytics);
  const [resetAllDataDrawer] = useResetAllDataDrawer();
  const [resetAllIndexDrawer] = useResetAllIndexDrawer();
  const [resetDataFilters] = useResetDataFilters();
  const [resetIndexFilters] = useResetIndexFilters();
  const location = useLocation();
  const [updateDrawer] = useInitDrawer();

  useEffect(() => {
    if (location?.state?.companyId) {
      resetDataFilters();
      resetIndexFilters();
      updateDrawer();
    } else {
      resetAllDataDrawer();
      resetAllIndexDrawer();
    }
  }, [openDataDrawer, openIndexDrawer]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="62vh"
      sx={{
        '@media(max-width: 768px)': { flexDirection: 'column', alignItems: 'left' },
      }}
    >
      <Stack spacing={3} height="100%" justifyContent="center">
        <Typography color="#747474" fontWeight="500" fontSize="28px">
          {t('Create customized data')}
        </Typography>
        <Button
          startIcon={<ControlPointIcon />}
          variant="contained"
          sx={buttonStyle}
          onClick={() => setOpenGeneratorModal(true)}
        >
          {t('Create')}
        </Button>
      </Stack>
      <GeneratorModal
        open={openGeneratorModal}
        closeModal={() => setOpenGeneratorModal(false)}
        handleTutorialModal={() => setOpenTutorialModal(true)}
        handleDataDrawer={() => setOpenDataDrawer(true)}
        handleIndexDrawer={() => setOpenIndexDrawer(true)}
      />
      <TutorialModal open={openTutorialModal} closeModal={() => setOpenTutorialModal(false)} />
      <DataDrawer
        open={openDataDrawer}
        data={dataQuery}
        action="create"
        closeDrawer={() => setOpenDataDrawer(false)}
      />
      <IndexDrawer
        open={openIndexDrawer}
        data={indexQuery}
        action="create"
        closeDrawer={() => setOpenIndexDrawer(false)}
      />
    </Stack>
  );
};

export default InitiateCTA;
